import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Pop = props => {
  const [email, setEmail] = useState('')
  const [valid, setValid] = useState(true)

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email)
      .then(data => {
        if (data.result === 'success') {
          setValid(true)
          return window.open('https://www.richardweale.com/success/')
        } else {
          setValid(false)
        }
      })
      .catch(error => {})
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  return (
    <div className="pop">
      <form onSubmit={handleSubmit} className="inner-pop">
        <h1>Where Should I Send Your Free Book?</h1>

        <p>
          Enter your email address to get your free book delivered to your
          inbox. You’ll also be added to my Reader’s Group so you’ll be the
          first to know when I have some cool stuff to give away. (You can
          unsubscribe at any time).
        </p>
        <button
          className="close-pop"
          onClick={() => props.onClick(event.target.onlick)}
        >
          <span>x</span>
        </button>
        <input
          name="email"
          type="text"
          placeholder="Email"
          onChange={handleEmailChange}
        />

        {valid ? null : (
          <div className="valid-email">
            <p>This email is invalid or already taken!</p>
            <p>Please try another...</p>
          </div>
        )}

        <input
          className="submit-mail-chimp"
          name="submit"
          type="submit"
          value="Send My Free Book"
        />

        <p className="spam">We value your privacy and would never spam you</p>
      </form>
    </div>
  )
}

export default Pop

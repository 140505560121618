import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Layout from '../components/LayoutLanding'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import '../styles/styles.scss'
import styled from 'styled-components'
import Pop from '../components/Pop'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const richardWealeReadersClub = ({ className }) => {
  return (
    <Layout>
      <div className="wrap">
        <StaticQuery
          query={graphql`
            query {
              contentfulReadersClubPage {
                mainText {
                  json
                }
              }
              image: file(relativePath: { eq: "thelift.jpeg" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              book: file(relativePath: { eq: "thelift3dbook.png" }) {
                childImageSharp {
                  fixed(width: 370) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          `}
          render={data => {
            const image = data.image.childImageSharp.fluid
            const book = data.book.childImageSharp.fixed
            const text = data.contentfulReadersClubPage.mainText.json

            const [clickState, setClickState] = useState(false)

            return (
              <BackgroundImage className={className} fluid={image}>
                <div className="landing">
                  <Img fixed={book} alt="" />
                  <div className="landing-text-wrap">
                    {documentToReactComponents(text)}
                    {/* <h1>The Pages Turn Themselves</h1>
                    <p>
                      To get your copy of APPRENTICE, you just need to tell me
                      where to send it...
                    </p> */}
                    <button
                      className="landing-button"
                      onClick={() => {
                        setClickState(!clickState)
                      }}
                    >
                      Get My Free Book!
                    </button>
                    {clickState ? (
                      <Pop
                        onChange={value => setNameState(value)}
                        onClick={() => setClickState(!clickState)}
                      />
                    ) : null}
                  </div>
                </div>
              </BackgroundImage>
            )
          }}
        />
      </div>
    </Layout>
  )
}

const StyledBackgroundSectionFoot = styled(richardWealeReadersClub)`
  width: 100%;
  background-size: cover;
  height: calc(100vh - 90px);
  background-repeat: no-repeat;
  @media (max-width: 900px) {
    height: 900px;
    min-height: 900px;
  }
`

export default StyledBackgroundSectionFoot
